body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.appwrapper {
    background-color: #0b1222;
    color: white;
    min-height: 100vh;
    min-width: 360px;
}

.no-outline:focus {
    outline: 0;
}

.Toastify__toast--success {
    background-color: #19212b !important;
    border-radius: 6px !important;
    border: 1px solid #455b77 !important;
}

.Toastify__toast--error {
    background-color: #19212b !important;
    border-radius: 6px !important;
    border: 1px solid #455b77 !important;
}

.Toastify__toast--warning {
    background-color: #19212b !important;
    border-radius: 6px !important;
    border: 1px solid #455b77 !important;
}

.Toastify__toast--error svg {
    color: red !important;
}

.Toastify__toast--warning svg {
    color: orange !important;
}

.Toastify__toast--success svg {
    color: greenyellow !important;
}
